(function ($) {
  Drupal.behaviors.faqFormatterV1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $template = $('.js-faq-formatter-v1', context);
      var $questionSections = $('.faq-content', $template);

      // questions sections
      if ($questionSections.length) {
        $questionSections.each(function () {
          var $this = $(this);
          var $questionWrapper = $('.faq-content__question', $this);
          var $questionCopy = $('.mantle-custom-text', $questionWrapper);
          var $trigger = $this.find('.js-faq-content-button');

          if ($questionCopy.length) {
            $trigger.attr('aria-label', $questionCopy.text())
          }
          // click handler
          $trigger.once().on('click', function () {
            var $this = $(this);
            var $parent = $this.parent();
            var $content = $this.siblings('.faq-content__answer');
            // Cast the expanded state as a boolean
            var expanded = $parent.attr('aria-expanded') === 'false' ? false : true;

            // Switch the states of aria-expanded and aria-hidden
            $parent.attr('aria-expanded', !expanded);
            $content.attr('aria-hidden', expanded);
          });
        });
      }
    },
    attached: false
  };
})(jQuery);
